var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.productId === 1
      ? "box-life"
      : _vm.productId === 2
      ? "box-dream"
      : _vm.productId === 3
      ? "box-protection"
      : _vm.productId === 4
      ? "box-credit"
      : _vm.productId === 5
      ? "box-exchanged"
      : _vm.productId === 6
      ? "box-real-state"
      : _vm.productId === 7
      ? "box-health"
      : ''},[(_vm.productId === 1)?_c('span',[_c('IconLife')],1):_vm._e(),(_vm.productId === 7)?_c('span',[_c('IconHealth')],1):_vm._e(),(_vm.productId === 2)?_c('span',[_c('IconDream')],1):_vm._e(),(_vm.productId === 3)?_c('span',[_c('IconProtection')],1):_vm._e(),(_vm.productId === 4)?_c('span',[_c('IconMoney')],1):_vm._e(),(_vm.productId === 5)?_c('span',[_c('IconExchange')],1):_vm._e(),(_vm.productId === 6)?_c('span',[_c('IconRealState')],1):_vm._e(),_c('div',[_c('div',[_vm._v(" "+_vm._s(_vm.productName.toUpperCase())+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }