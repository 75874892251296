<template>
  <div
    :class="
      productId === 1
        ? `box-life`
        : productId === 2
        ? `box-dream`
        : productId === 3
        ? `box-protection`
        : productId === 4
        ? `box-credit`
        : productId === 5
        ? `box-exchanged`
        : productId === 6
        ? `box-real-state`
        : productId === 7
        ? `box-health`
        : ''
    "
  >
    <span v-if="productId === 1"><IconLife /></span>
    <span v-if="productId === 7"><IconHealth /></span>
    <span v-if="productId === 2"><IconDream /></span>
    <span v-if="productId === 3"><IconProtection /></span>
    <span v-if="productId === 4"><IconMoney /></span>
    <span v-if="productId === 5"><IconExchange /></span>
    <span v-if="productId === 6"><IconRealState /></span>

    <div>
      <div>
        {{ productName.toUpperCase() }}
      </div>
    </div>
  </div>
</template>

<script>
import IconLife from '@/assets/images/icons/products/IconLife.vue';
import IconHealth from '@/assets/images/icons/products/IconHealth.vue';
import IconDream from '@/assets/images/icons/products/IconDream.vue';
import IconProtection from '@/assets/images/icons/products/IconProtection.vue';
import IconMoney from '@/assets/images/icons/products/IconMoney.vue';
import IconExchange from '@/assets/images/icons/products/IconExchange.vue';
import IconRealState from '@/assets/images/icons/products/IconRealState.vue';

export default {
  components: {
    IconLife,
    IconHealth,
    IconDream,
    IconProtection,
    IconMoney,
    IconExchange,
    IconRealState
  },
  props: {
    productId: Number,
    productName: String
  }
};
</script>

<style lang="scss" scoped>
.box-life {
  display: flex;
  align-items: center;

  background: var(--product-life);
  color: var(--white);
  width: 9rem;
  padding: 10px;
  border-radius: 5px;

  svg {
    margin-right: 1rem;
  }

  span {
    font-weight: 200;
  }
}

.box-health {
  display: flex;
  align-items: center;

  background: var(--product-health);
  color: var(--white);
  width: 9rem;
  padding: 10px;
  border-radius: 5px;

  i {
    margin-right: 1rem;
  }

  span {
    font-weight: 200;
  }
}

.box-exchanged {
  display: flex;
  align-items: center;

  background: var(--product-exchange);
  color: var(--white);
  width: 9rem;
  padding: 10px;
  border-radius: 5px;

  svg {
    margin-right: 1rem;
  }

  span {
    font-weight: 200;
  }
}

.box-credit {
  display: flex;
  align-items: center;

  background: var(--product-credit);
  color: var(--white);
  width: 9rem;
  padding: 10px;
  border-radius: 5px;

  svg {
    margin-right: 1rem;
  }

  span {
    font-weight: 200;
  }
}

.box-dream {
  display: flex;
  align-items: center;

  background: var(--product-dream);
  color: var(--white);
  width: 10rem;
  padding: 7px;
  border-radius: 5px;
  font-size: 10px;

  svg {
    margin-right: 1rem;
  }

  span {
    font-weight: 200;
  }
}

.box-protection {
  display: flex;
  align-items: center;

  background: var(--product-protection);
  color: var(--white);
  width: 9rem;
  padding: 7px;
  border-radius: 5px;
  font-size: 10px;

  svg {
    margin-right: 1rem;
  }

  span {
    font-weight: 200;
  }
}

.box-real-state {
  display: flex;
  align-items: center;

  background: var(--product-real-state);
  color: var(--white);
  width: 9rem;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;

  svg {
    margin-right: 1rem;
  }

  span {
    font-weight: 200;
  }
}
</style>
