<template>
  <div>
    <div style="margin-top: 1rem">
      <div style="margin-top: 1rem" class="hrdivider">
        <span>DOCUMENTOS PENDENTES</span>
      </div>
      <div style="margin-top: 1rem">
        Faça o upload dos documentos listados abaixo:
      </div>

      <div
        style="margin: 1rem 0"
        v-for="document in documentsWaiting"
        :key="document.diagnostico_documento_id"
      >
        <div style="margin-bottom: 0px">
          {{ document.titulo }}

          <span v-if="document.download_url">
            - Enviado dia {{ document.data_alteracao | formatDate }}
          </span>

          <i style="margin-left: 5px" class="fas fa-exclamation-circle" />

          <div v-if="document.download_url" class="mb-2">
            <p class="mb-0">{{ document.nome_original }}</p>

            <div class="d-flex justify-content-between align-items-center">
              <a :href="document.download_url" target="_blank"> Visualizar </a>

              <b-button
                pill
                variant="danger"
                @click="() => handleDeleteFile(document)"
              >
                <i class="fas fa-trash-alt" />
              </b-button>
            </div>
          </div>
        </div>

        <b-form-file
          v-model="document.file"
          :file-name-formatter="formatNames"
          @input="() => handleFileUpload(document)"
        />
      </div>
    </div>

    <div class="mt-4" v-show="rejectedDocuments.length > 0">
      <div class="hrdivider">
        <hr style="background: red" />
        <p>
          Por favor, verifique as necessidades de ajustes e encaminhe os
          documentos corretos
        </p>
        <span style="color: #e21b32">DOCUMENTOS RECUSADOS</span>
      </div>

      <div
        v-for="document in rejectedDocuments"
        :key="document.diagnostico_documento_id"
        style="margin: 1rem 0"
      >
        <div class="d-flex align-items-end justify-content-between">
          <p style="margin-bottom: 6px; font-size: 10px; color: #e21b32">
            {{ document.titulo }} - Enviado dia
            {{ document.data_alteracao | formatDate }}
          </p>

          <b-button-group
            style="padding: 6px 0"
            @click="() => viewSpecialistComment(document)"
            v-if="
              document.diagnostico_comentario_especialista &&
              document.diagnostico_comentario_especialista.comentario
            "
          >
            <b-button
              style="
                background: #7392eb;
                border-color: #7392eb;
                height: 3rem;
                width: 3rem;
              "
            >
              <i class="fas fa-eye" style="font-size: 1rem"></i>
            </b-button>

            <b-button
              style="width: 7rem; height: 3rem; font-size: 10px"
              variant="primary"
            >
              Ver comentário do especialista
            </b-button>
          </b-button-group>
        </div>

        <b-form-file
          style="border: 1px solid red; border-radius: 5px"
          :file-name-formatter="formatNames"
          v-model="document.file"
        />
      </div>

      <b-button
        style="width: 100%; margin-top: 1rem"
        variant="primary"
        @click="handleRejectedFilesUpload()"
      >
        ENVIAR ARQUIVOS
      </b-button>
    </div>

    <div class="mt-4" v-show="verifiedDocuments.length > 0">
      <div class="hrdivider">
        <hr style="background: #078d42" />
        <span style="color: #078d42">VERIFICADOS</span>
      </div>

      <div
        v-for="document in verifiedDocuments"
        :key="document.diagnostico_documento_id"
        style="margin: 1rem 0"
      >
        <p style="margin-bottom: 0px; color: #078d42">
          <strong>{{ document.titulo }}</strong> - Enviado dia
          {{ document.data_alteracao | formatDate }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  uploadDocumentFile,
  deleteDocumentFile
} from '@/services/requests/diagnosis';

export default {
  name: 'TabViewDocuments',
  methods: {
    handleFileUpload(document) {
      if (!document.file) return;

      uploadDocumentFile(document.diagnostico_documento_id, document.file)
        .then(() => {
          this.$emit('loadDiagnosis');
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    handleRejectedFilesUpload() {
      const rejectedDocuments = this.rejectedDocuments.filter(
        (document) => document.file
      );

      if (rejectedDocuments.length === 0) return;

      const promises = [];

      rejectedDocuments.forEach((document) => {
        const promise = uploadDocumentFile(
          document.diagnostico_documento_id,
          document.file
        );
        promises.push(promise);
      });

      Promise.all(promises)
        .then(() => {
          this.$emit('loadDiagnosis');
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    viewSpecialistComment(document) {
      this.$store.state.modal.commentModalViewComment =
        document.comentario_especialista;
      this.$store.state.modal.isOpenModalViewComment = true;
    },

    handleDeleteFile(document) {
      if (!confirm('Deseja realmente excluir este arquivo?'));

      deleteDocumentFile(document.diagnostico_documento_id)
        .then((res) => {
          const documentIndex = this.diagnosis.diagnostico_documentos.findIndex(
            (diagnosisDocument) =>
              diagnosisDocument.diagnostico_documento_id ===
              document.diagnostico_documento_id
          );

          this.$set(
            this.diagnosis.diagnostico_documentos,
            documentIndex,
            res.data.result.data
          );
        })
        .catch(() => {
          alert('Houve um erro ao excluir o arquivo');
        });
    },

    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} arquivos selecionados`;
    }
  },
  computed: {
    diagnosis() {
      return this.$store.state.modal.dataInfoClientCard;
    },
    documentsWaiting() {
      if (!this.diagnosis) return [];

      return this.diagnosis.diagnostico_documentos.filter(
        (document) => document.status_aprovacao === 0
      );
    },
    verifiedDocuments() {
      if (!this.diagnosis) return [];

      return this.diagnosis.diagnostico_documentos.filter(
        (document) => document.status_aprovacao === 1
      );
    },
    rejectedDocuments() {
      if (!this.diagnosis) return [];

      return this.diagnosis.diagnostico_documentos.filter(
        (document) => document.status_aprovacao === 2
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 1rem;

  .icon-eye {
    font-size: 1.2rem;
    color: var(--blue);
  }

  .title-content {
    font-size: 14px;
    color: var(--blue);
    font-weight: 600;
    margin-left: 10px;
  }
}

.check-green {
  background: var(--green);
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.btn-icon-see-comment {
  height: 2rem;
  background: var(--border-blue-light);
  border-color: var(--border-blue-light);
}

.btn-text-see-comment {
  width: 6rem;
  height: 2rem;
  font-size: 9px;
}
</style>
