<template>
  <b-col md="4" class="mt-2 text-center">
    <router-link
      :to="{ name: step.route, params: { diagnosisId: diagnosisId } }"
      v-if="diagnosisId && step"
    >
      <b-button variant="primary">
        {{ name }}
        <p v-if="date" class="mb-0">
          {{ date | formatDate }}
        </p>
      </b-button>
    </router-link>

    <div v-else>
      <strong>{{ name }} </strong>
      <p v-if="date" class="mb-0">
        {{ date | formatDate }}
      </p>
    </div>
  </b-col>
</template>

<script>
export default {
  name: 'CustomerStepHistory',
  props: {
    name: String,
    date: String,
    diagnosisId: {
      type: Number,
      required: false,
      default: null
    },
    step: {
      type: Object,
      required: false,
      default: null
    }
  }
};
</script>
