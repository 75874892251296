<template>
  <div>
    <ModalEditComment @onSuccess="getComments()" />

    <!-- <b-form @submit="(event) => handleSubmit(event)">
      <b-form-textarea
        rows="5"
        placeholder="Adicione comentário sobre o histórico de contato"
        v-model="commentText"
      />
      <div class="mt-2">
        <b-button
          class="mr-2"
          type="button"
          variant="danger"
          style="width: 100px"
          @click="cancelAddComment()"
        >
          CANCELAR
        </b-button>

        <b-button type="submit" variant="success" style="width: 100px">
          OK
        </b-button>
      </div>
    </b-form> -->

    <div class="mt-5">
      <div class="text-center p-4" v-if="comments.length === 0 && !loading">
        Este estudo ainda não possui comentários
      </div>

      <div
        v-if="loading"
        class="d-flex justify-content-center mb-3"
        style="margin: 2rem 0"
      >
        <b-spinner variant="primary" type="grow" />
      </div>

      <div>
        <div
          v-for="(comment, index) in comments"
          :key="index"
          class="border-bottom py-2"
        >
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <AvatarLetters
                class="mr-3"
                :name="comment.usuario.pessoa.pessoa_nome"
              />

              <div>
                {{ comment.data_criacao | formatDate }}
                {{ comment.data_criacao | formatTime }}

                <p class="mb-0">
                  <strong>
                    {{ comment.usuario.pessoa.pessoa_nome }}
                  </strong>
                </p>
              </div>
            </div>

            <div class="d-flex justify-content-center">
              <div>
                <ProductsBox
                  :productId="
                    comment.diagnostico_produto_item.diagnostico_produto
                      .produto_diagnostico_id
                  "
                  :productName="
                    comment.diagnostico_produto_item.diagnostico_produto
                      .produto_diagnostico.nome
                  "
                  v-if="comment.diagnostico_produto_item"
                />
                <b-badge v-if="comment.diagnostico_documento_id">
                  Documento
                </b-badge>
              </div>

              <!-- <EditButton
                active
                class="mr-2"
                @click.native="() => openModalEditComment(comment)"
              />
              <TrashButton active :click="() => handleDelete(comment)" /> -->
            </div>
          </div>

          <div class="mt-2">
            <p
              style="font-size: 12px"
              v-if="!readMoreActivated[index]"
              class="mb-1"
            >
              {{ comment.comentario.slice(0, 200) }}
            </p>

            <p
              style="font-size: 12px"
              v-if="readMoreActivated[index]"
              class="mb-1"
            >
              {{ comment.comentario }}
            </p>

            <span class="text-primary" v-if="comment.comentario.length > 200">
              <span
                class="cursor-pointer"
                v-if="!readMoreActivated[index]"
                @click="() => activateReadMore(index)"
              >
                Ler mais <i class="fas fa-arrow-right" />
              </span>

              <span
                class="cursor-pointer"
                v-if="readMoreActivated[index]"
                @click="() => activateReadMore(index)"
              >
                Ler menos
              </span>
            </span>
          </div>
        </div>
      </div>

      <hr class="my-4" />

      <div>
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="pagination.total"
          pills
          :per-page="pagination.perPage"
          class="d-flex justify-content-center"
          @change="getComments"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AvatarLetters from '@/components/common/AvatarLetters';
import ProductsBox from '@/components/Products/ProductsBox';
// import EditButton from '@/components/common/Buttons/EditButton.vue';
// import TrashButton from '@/components/common/Buttons/TrashButton.vue';
import ModalEditComment from '@/components/common/Modals/ModalEditComment';

import { api } from '@/utils/api';
import { cloneObject } from '@/helpers';
import { fields } from '@/constants/options';
import { createComment } from '@/services/requests/diagnosis';

export default {
  components: {
    AvatarLetters,
    ProductsBox,
    // EditButton,
    // TrashButton,
    ModalEditComment
  },
  props: {
    diagnosis: Object
  },
  data() {
    return {
      loading: false,
      saving: false,

      commentText: '',
      comments: [],

      sortBy: 'last_update',
      selectedDateOrder: 'asc',
      fields,
      pagination: {
        total: 0,
        lastPage: 1,
        perPage: 10,
        currentPage: 1
      },
      readMoreActivated: {}
    };
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();

      this.addComment();
    },
    addComment() {
      this.saving = true;

      const data = {
        diagnostico_id: this.diagnosis.diagnostico_id,
        comentario: this.commentText
      };

      createComment(data)
        .then(() => {
          this.saving = false;

          this.clearFormComment();
          this.getComments();
        })
        .catch(() => {
          this.saving = false;
        });
    },
    cancelAddComment() {
      this.clearFormComment();
    },
    clearFormComment() {
      this.commentText = '';
    },
    getComments(page = 1) {
      this.loading = true;

      const params = {
        page: page,
        ordem_data: this.selectedDateOrder
        // especialista: true
      };

      api
        .get(
          `motor-interno/diagnostico/${this.diagnosis.diagnostico_id}/comentarios`,
          {
            params
          }
        )
        .then((res) => {
          this.loading = false;
          const { data } = res.data.result;

          this.pagination = {
            total: data.total,
            lastPage: data.last_page,
            perPage: data.per_page,
            currentPage: data.current_page
          };

          this.comments = data.data;
        })
        .catch(() => {
          this.loading = false;
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },
    activateReadMore(index) {
      const readMoreActivated = cloneObject(this.readMoreActivated);

      readMoreActivated[index] = !readMoreActivated[index];

      this.$set(this, 'readMoreActivated', readMoreActivated);
    },
    openModalEditComment(comment) {
      this.$store.state.modal.commentModalEditComment = comment;
      this.$store.state.modal.isOpenModalEditComment = true;
    },
    handleDelete(comment) {
      const confirm = window.confirm('Deseja realmente excluir este estudo?');
      if (!confirm) return;

      const commentId = comment.diagnostico_comentario_id;

      api
        .delete(`/motor-interno/diagnostico-comentario/${commentId}`)
        .then(() => {
          this.getComments();
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    }
  },
  watch: {
    diagnosis: function () {
      this.getComments();
    }
  },
  mounted() {
    this.getComments();
  }
};
</script>

<style lang="scss" scoped></style>
