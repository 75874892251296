<template>
  <CustomerStep
    :name="step.name"
    :date="historicalDate"
    :step="step"
    :diagnosisId="diagnosis.diagnostico_id"
    v-if="historical"
  />
</template>

<script>
import CustomerStep from './CustomerStep';

export default {
  name: 'CustomerStepHistory',
  props: {
    step: Object,
    diagnosis: Object,
    isSpecialist: Boolean
  },
  components: {
    CustomerStep
  },
  computed: {
    historical() {
      if (!this.diagnosis) return null;

      const historico = this.isSpecialist
        ? this.diagnosis.diagnostico_historicos_especialista
        : this.diagnosis.diagnostico_historicos;

      return historico.find(
        (history) => history.estagio_id === this.step.stageId
      );
    },
    historicalDate() {
      if (!this.historical) return null;

      return this.historical.data_criacao;
    }
  }
};
</script>
