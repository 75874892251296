<template>
  <b-row>
    <CustomerStep
      name="Data de cadastro"
      :date="diagnosis.pessoa.cliente.data_criacao"
    />

    <CustomerStepHistory
      v-for="(step, index) in steps"
      :key="index"
      :step="step"
      :diagnosis="diagnosis"
      :isSpecialist="isSpecialist"
    />
  </b-row>
</template>

<script>
import CustomerStep from './CustomerStep';
import CustomerStepHistory from './CustomerStepHistory';

export default {
  name: 'CustomerStepsHistory',
  props: {
    diagnosis: Object,
    isSpecialist: Boolean
  },
  components: {
    CustomerStep,
    CustomerStepHistory
  },
  data() {
    return {
      steps: [
        {
          name: 'Preparar cotação',
          route: 'ProposalAndQuotation',
          stageId: 1
        },
        {
          name: 'Verificar resposta da proposta',
          route: 'CheckProposalResponse',
          stageId: 2
        },
        {
          name: 'Efetivação da proposta',
          route: 'ProposalExecution',
          stageId: 3
        },
        {
          name: 'Imputar propostas',
          route: 'InputProposals',
          stageId: 4
        },
        {
          name: 'Aprovação pelo fornecedor',
          route: 'SupplierApproval',
          stageId: 5
        },
        {
          name: 'Aguardando consultor',
          route: 'AwaitingConsultant',
          stageId: 6
        }
      ]
    };
  }
};
</script>
