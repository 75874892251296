<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for  -->
  <b-modal
    v-model="show"
    hide-footer
    size="xl"
    header-class="p-0"
    id="modal-info-client-card"
  >
    <ModalViewCommentOld title="Comentário do especialista" />

    <template #modal-header="{ close }">
      <div class="container-left-content p-0" style="height: 52.5px"></div>

      <div class="p-3">
        <button type="button" aria-label="Close" class="close" @click="close()">
          ×
        </button>
      </div>
    </template>

    <template>
      <b-row class="header-modal m-0" v-if="diagnosis">
        <div class="container">
          <!-- header -->
          <div>
            <b-row class="header-info m-0">
              <b-col class="d-flex pl-0 align-items-center">
                <AvatarLetters
                  :name="diagnosis.pessoa.pessoa_nome"
                  class="mr-2"
                />

                <div class="d-flex flex-column box-name-atrasado">
                  <!-- <span v-if="true && tabIndex === 0" class="atrasado"
                    >Atrasado</span
                  >
                  <span v-if="false && tabIndex === 0" class="pontual"
                    >Pontual</span
                  > -->

                  <span class="name-client-header">
                    {{ diagnosis.pessoa.pessoa_nome }}
                  </span>
                </div>
              </b-col>

              <!-- <b-col class="d-flex align-items-center">
                <div class="container-btns-prev-next">
                  <b-button-group class="container-buttons">
                    <b-button
                      variant="outline-secondary"
                      :disabled="!previousItemIdClientCard"
                      @click="prevStatus()"
                    >
                      <i
                        style="
                          margin-right: 5px;
                          font-size: 14px;
                          line-height: 19.5px;
                        "
                        class="fas fa-caret-left"
                      />

                      <span>Anterior</span>
                    </b-button>

                    <b-button
                      :style="`background: ${
                        diagnosis.diagnostico_status_id === 1
                          ? '#FFBA04'
                          : diagnosis.diagnostico_status_id === 2
                          ? 'var(--status-2)'
                          : diagnosis.diagnostico_status_id === 3
                          ? 'var(--status-3)'
                          : diagnosis.diagnostico_status_id === 4
                          ? 'var(--status-4)'
                          : diagnosis.diagnostico_status_id === 5
                          ? 'var(--status-5)'
                          : diagnosis.diagnostico_status_id === 6
                          ? 'var(--status-6)'
                          : diagnosis.diagnostico_status_id === 7
                          ? 'var(--status-7)'
                          : diagnosis.diagnostico_status_id === 8
                          ? 'var(--status-8)'
                          : diagnosis.diagnostico_status_id === 9
                          ? 'var(--status-9)'
                          : diagnosis.diagnostico_status_id === 10
                          ? 'var(--status-10)'
                          : diagnosis.diagnostico_status_id === 11 &&
                            'var(--status-11)'
                      };
                        color: var(--white);
                        border: none;
                        width: 10rem;
                        text-align: center;
                        `"
                    >
                      <span style="font-size: 90%">
                        {{ diagnosis.diagnostico_status_id }}.
                        {{
                          diagnosis.diagnostico_status_id === 1
                            ? 'PROSPECÇÃO'
                            : diagnosis.diagnostico_status_id === 2
                            ? 'LEVANTAMENTO DE NECESSIDADES'
                            : diagnosis.diagnostico_status_id === 3
                            ? 'DIRECIONAMENTO DO CONSULTOR'
                            : diagnosis.diagnostico_status_id === 4
                            ? 'COTAÇÃO'
                            : diagnosis.diagnostico_status_id === 5
                            ? 'RESULTADO DO ESTUDO'
                            : diagnosis.diagnostico_status_id === 6
                            ? 'RECOMENDAÇÃO'
                            : diagnosis.diagnostico_status_id === 7
                            ? 'AJUSTES'
                            : diagnosis.diagnostico_status_id === 8
                            ? 'FECHAMENTO'
                            : diagnosis.diagnostico_status_id === 9
                            ? 'PROTOCOLAR NO FORNECEDOR'
                            : diagnosis.diagnostico_status_id === 10 &&
                              'EFETIVAÇÃO NO FORNECEDOR'
                        }}
                      </span>
                    </b-button>

                    <b-button
                      variant="outline-secondary"
                      :disabled="!nextItemIdClientCard"
                      @click="nextStatus()"
                    >
                      <span>Próximo</span>

                      <i
                        style="
                          margin-left: 5px;
                          font-size: 14px;
                          line-height: 19.5px;
                        "
                        class="fas fa-caret-right"
                      />
                    </b-button>
                  </b-button-group>
                </div>

                <div
                  v-if="tabIndex === 0"
                  @click="deleteDiagnosis()"
                  class="ml-2 d-flex align-items-center justify-content-center"
                  style="
                    cursor: pointer;
                    border-radius: 50%;
                    width: 2rem;
                    height: 2rem;
                    background: var(--red);
                  "
                >
                  <i class="fas fa-trash-alt text-white" />
                </div>

                <div v-if="tabIndex !== 0">
                  <b-dropdown
                    size="lg"
                    variant="link"
                    toggle-class="text-decoration-none pr-0"
                    style="margin-left: 6px"
                    no-caret
                  >
                    <template #button-content>
                      <i
                        class="fas fa-ellipsis-h"
                        style="color: var(--gray-700)"
                      />
                    </template>

                    <b-dropdown-item href="#"
                      ><i
                        class="fas fa-pencil-alt"
                        style="color: var(--green); margin-right: 1rem"
                      />

                      <span>Editar</span>
                    </b-dropdown-item>

                    <hr style="width: 80%; margin: 5px auto" />

                    <b-dropdown-item href="#">
                      <i
                        class="fas fa-trash-alt"
                        style="color: var(--red); margin-right: 1rem"
                      />

                      <span>Excluir</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-col> -->
            </b-row>

            <!-- <b-row class="m-0 mt-3">
              <span>Status:</span>
              <b-form-select
                v-model="diagnosis.diagnostico_status_id"
                :options="optionsStatus"
                @change="(value) => updateDiagnosisStatus(value)"
              />
            </b-row> -->
          </div>

          <!-- main content -->
          <div class="container-main-content mt-3">
            <b-tabs v-model="tabIndex" responsive content-class="mt-3">
              <CustomerStepsHistory
                :diagnosis="diagnosis"
                class="my-3"
                :isSpecialist="isSpecialist"
                v-if="isSpecialist"
              />

              <!-- <b-tab title="Dados do contato" active>
                <TabContactDetails :diagnosis="diagnosis" />
              </b-tab>

              <b-tab title="Histórico de contato">
                <TabContactHistory
                  :diagnosis="diagnosis"
                  :isSpecialist="isSpecialist"
                />
              </b-tab> -->

              <b-tab
                :title="
                  isSpecialist ? 'Chat com consultor' : 'Chat com especialista'
                "
              >
                <TabChatWithSpecialists
                  :diagnosis="diagnosis"
                  :isSpecialist="isSpecialist"
                />
              </b-tab>

              <b-tab title="Chat com especialista univalores">
                <TabChatWithSpecialists
                  :diagnosis="diagnosis"
                  :isSpecialist="isSpecialist"
                />
              </b-tab>

              <b-tab title="Visualizar documentos">
                <TabViewDocuments
                  :diagnosis="diagnosis"
                  :isSpecialist="isSpecialist"
                  @loadDiagnosis="loadDiagnosis()"
                />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import TabChatWithSpecialists from './Tabs/ChatWithSpecialists';
import TabViewDocuments from './Tabs/TabViewDocuments';
import AvatarLetters from '@/components/common/AvatarLetters';
import ModalViewCommentOld from '@/components/common/Modals/ModalViewCommentOld';
import CustomerStepsHistory from './CustomerStepsHistory';

import { cloneObject } from '@/helpers';
import { optionsStatus } from '@/constants/options';

import {
  updateDiagnosis,
  deleteCommentDiagnosis,
  requestDiagnosis
} from '@/services/requests/diagnosis';

import firstLettersOfNames from '@/mixins/first-letters-of-names';
import { mapActions, mapState } from 'vuex';

export default {
  mixins: [firstLettersOfNames],
  props: {
    id: String,
    isSpecialist: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    AvatarLetters,
    CustomerStepsHistory,
    TabChatWithSpecialists,
    TabViewDocuments,
    ModalViewCommentOld
  },
  data() {
    return {
      tabIndex: 0,
      optionsStatus
    };
  },
  methods: {
    ...mapActions('modal', ['openModalInfoClientCard']),
    prevStatus() {
      if (!this.previousItemIdClientCard) return;

      const previousItemIdClientCard = cloneObject(
        this.previousItemIdClientCard
      );

      this.$emit('onNavigate', previousItemIdClientCard);
      this.openModalInfoClientCard(previousItemIdClientCard);
    },

    nextStatus() {
      if (!this.nextItemIdClientCard) return;

      const nextItemIdClientCard = cloneObject(this.nextItemIdClientCard);

      this.$emit('onNavigate', nextItemIdClientCard);
      this.openModalInfoClientCard(nextItemIdClientCard);
    },

    updateDiagnosisStatus(value) {
      this.diagnosis.pessoa.pessoa_nomeStatus = this.optionsStatus.find(
        (status) => status.value === value
      ).text;

      const diagnosisId = this.diagnosis.diagnostico_id;
      const data = {
        diagnostico_status_id: value
      };

      updateDiagnosis(diagnosisId, data)
        .then(() => {
          this.$emit('onUpdate');
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    deleteDiagnosis() {
      const confirm = window.confirm('Deseja realmente excluir este estudo?');
      if (!confirm) return;

      const diagnosisId = this.diagnosis.diagnostico_id;

      deleteCommentDiagnosis(diagnosisId)
        .then(() => {
          this.$bvModal.hide('modal-info-client-card');
          this.$emit('onDelete');
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    loadDiagnosis() {
      requestDiagnosis(this.diagnosis.diagnostico_id)
        .then((res) => {
          this.$store.state.modal.dataInfoClientCard = res.data.result.data;
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    }
  },
  computed: {
    ...mapState('modal', ['previousItemIdClientCard', 'nextItemIdClientCard']),
    show: {
      get() {
        return this.$store.state.modal.isOpenModalInfoClientCard;
      },
      set() {
        this.$store.state.modal.isOpenModalInfoClientCard = !this.$store.state
          .modal.isOpenModalInfoClientCard;
      }
    },
    diagnosis() {
      return this.$store.state.modal.dataInfoClientCard;
    },
    diagnosisStatusColor() {
      if (!this.diagnosis) return null;

      const diagnosisStatusId = this.diagnosis.diagnostico_status
        .diagnostico_status_id;

      if (diagnosisStatusId === 1) return 'var(--diagnosis-1)';
      if (diagnosisStatusId === 2) return 'var(--diagnosis-2)';
      if (diagnosisStatusId === 3) return 'var(--diagnosis-3)';
      if (diagnosisStatusId === 4) return 'var(--diagnosis-4)';
      if (diagnosisStatusId === 5) return 'var(--diagnosis-5)';
      if (diagnosisStatusId === 6) return 'var(--diagnosis-6)';
      if (diagnosisStatusId === 7) return 'var(--diagnosis-7)';
      if (diagnosisStatusId === 8) return 'var(--diagnosis-8)';
      if (diagnosisStatusId === 9) return 'var(--diagnosis-9)';
      if (diagnosisStatusId === 10) return 'var(--diagnosis-10)';
      if (diagnosisStatusId === 11) return 'var(--diagnosis-11)';

      return null;
    }
  }
};
</script>
<style lang="scss" scoped>
#modal-info-client-card .modal-body {
  padding: 0;
}

.header-modal {
  margin: 0;
  border-radius: 5px;

  background: var(--body-bg);
}

.name-client-header {
  font-weight: 600;
  font-size: 15px;
  color: var(--blue);
}

.container-left-content {
  padding: 1rem;
  background: #f8f8fb;
}

@media (max-width: 991px) {
  .container-buttons {
    width: 20rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container-buttons {
    width: 30rem;
  }

  .container-right-content {
    padding: 1rem;
    border-bottom-right-radius: 5px;

    .icon-eye {
      font-size: 1.2rem;
      color: var(--blue);
    }

    .title-right-content {
      font-size: 14px;
      color: var(--blue);
      font-weight: 600;
      margin-left: 10px;
    }
  }
}

@media (min-width: 1200px) {
  .img-profile {
    border-radius: 50%;
    width: 4rem;
  }

  .img-profile-comment {
    border-radius: 50%;
    width: 2.5rem;
  }

  .container-buttons {
    width: 22rem;
  }

  .btn-icon-see-comment {
    height: 2rem;
    background: var(--border-blue-light);
    border-color: var(--border-blue-light);
  }

  .btn-text-see-comment {
    width: 6rem;
    height: 2rem;
    font-size: 9px;
  }
}
</style>
